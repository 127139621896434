import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component';
import { MatIconModule } from '@angular/material/icon';
import { PaymentAlertComponent } from './payment-alert.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AlertsComponent, PaymentAlertComponent],
  exports: [AlertsComponent, PaymentAlertComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
})
export class AlertsModule {}
