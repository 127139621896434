import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'fc-alerts',
  template: `
    <div class="alert-container {{ type }}">
      <div class="block">
        <div class="alert-icon">
          <mat-icon [fontIcon]="iconName"></mat-icon>
        </div>
        <div class="alert-message">
          <ng-content></ng-content>
        </div>
      </div>
      <button
        class="close-button"
        *ngIf="closeButton"
        mat-icon-button
        (click)="closeEmitter.emit()"
      >
        <mat-icon fontIcon="icon-clear"></mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['./alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsComponent {
  @Input() closeButton: boolean;
  @Input() type: 'success' | 'notify' | 'warning' | 'error';
  @Output() closeEmitter = new EventEmitter<void>();
  get iconName(): string {
    switch (this.type) {
      case 'success':
        return 'icon-check_circle';
      case 'warning':
        return 'icon-warning';
      case 'notify':
        return 'icon-cancel';
      case 'error':
        return 'icon-highlight_off';
    }
  }
}
